import React from "react"
import { Global, css } from '@emotion/react'
import styled from "@emotion/styled";
import SEO from "../components/seo";
import Header from "../components/header";
import Footer from "../components/footer";
import FindYourHappinessImage from '../images/flavour/find-your-happiness.jpg';
import LaffMattazImage from '../images/flavour/laff-mattaz.jpg';
import AyLiveImage from '../images/flavour/ay-live.jpg';
import ThousandSmilesImage from '../images/flavour/1000-smiles.jpg';
import NickfestImage from '../images/flavour/nickfest.jpg';

const globalCss = css`
    @font-face {
        font-family: 'Brandon';
        src: url('/fonts/Brandon_reg.otf') format('opentype');
        font-display: swap;
    }

    @font-face {
        font-family: 'Brandon';
        src: url('/fonts/Brandon_bld.otf') format('opentype');
        font-weight: bold;
        font-display: swap;
    }

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    font-family: "Brandon";
    margin: 0;
    padding: 0;
  }
`;

const OrangeSection = styled.section`
    background: radial-gradient(circle at center, #ffc43e 20%, #f68507 60%);
`;

const ContentSection = styled.section`
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 1rem;
`;

const Heading = styled.h2`
  font-size: 2.5em;
  margin-bottom: 0;
  word-wrap: break-word;

  span {
    color: #d25210;
  }

  @media (max-width: 60rem) {
    font-size: 2.5em;
    font-size: 1.5rem;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const FindYourHappinessPage = () => {
  return (
    <>
      <SEO title="Find Your Happiness - Maltina" />
      <main>
        <Global styles={globalCss} />
        <OrangeSection>
          <Header compact={true} />
        </OrangeSection>

        <ContentSection>
          <Heading>#FindYour<span>Happiness</span></Heading>
          <p>
            This is a campaign that highlights instances where we can all find happiness. An example is that feeling of relief the moment you take off your shoes after a very long day at work or the happiness that comes for ladies taking off their wigs with compliments from the unfettered harshness of a very sunny day. Another relatable scenario is a teenage boy discovering that spikes of beard are beginning to grow on his jaw.
          </p>
          <a href="https://www.youtube.com/channel/UCY2PTQACTRBmmzN7XxYxCYA/videos" target="_blank" rel="noreferrer">
            <Image alt="" src={FindYourHappinessImage} />
          </a>
        </ContentSection>

        <ContentSection>
          <Heading><span>#LaffMattaz</span></Heading>
          <p>
            Maltina is the proud sponsor of the most talked about comedy show in Southwest Nigeria tagged “Laff Mattazz”. The show host is none other than the well known veteran Gbenga Adeyinka aka ‘The Grand Comedian of the Federal Republic (GCFR)’. With this opportunity, Maltina is looking to tour the Southwest while sharing and celebrating in happy moments with its consumers.
          </p>
          <a href="https://www.instagram.com/p/BwY0Ec9nI_B/" target="_blank" rel="noreferrer">
            <Image alt="" src={LaffMattazImage} />
          </a>
        </ContentSection>

        <ContentSection>
          <Heading><span>#AYLive</span>EasterSunday</Heading>
          <p>
            AY Live has been powered by the Nation’s favourite malt drink for four years. So it is no news that Maltina is always on the lookout for opportunities to make every moment worthy of happiness.
          </p>
          <Image alt="" src={AyLiveImage} />
        </ContentSection>

        <ContentSection>
          <Heading><span>#Maltina1000Smiles</span>WithNoble</Heading>
          <p>
            Maltina partners Noble Igwe to curate smiley moments from different faces around Nigeria. This campaign had a kickstart in November 2018 all through March 2019. The campaign is based on the fact that there are always reasons to smile. Give a smile. Get a smile.
          </p>
          <Image alt="" src={ThousandSmilesImage} />
        </ContentSection>

        <ContentSection>
          <Heading><span>#NickFest</span>Nigeria</Heading>
          <p>
            Maltina is the official sponsor of Nickfest in Nigeria and have sponsored the event for over 3 years. This is a great opportunity for kids all over Nigeria to come celebrate their favourite Nickelodeon characters and there are usually loads of reasons to smile at these events.
          </p>
          <Image alt="" src={NickfestImage} />
        </ContentSection>
        <Footer />
      </main>
    </>
  )
}

export default FindYourHappinessPage
